.footer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    padding-bottom: 1rem;
    margin-top: 1rem;
}

.first-footer {
    padding: 0 4rem;
}

.footer-link-1, .footer-link-2, .footer-link-3 {
    display: flex;
    flex-direction: column;
}

.footer-link-heading {
    margin-left: 0;
    padding-left: 0;
    font-size: 1.2rem;
    font-family: "Raleway", monospace;
    font-weight: 600;
    color: #333;
    margin-bottom: 1rem;
}

.footer-link-text {
    font-size: 1rem;
    font-family: "Raleway", monospace;
    font-weight: 400;
    color: #0029CF;
    margin-bottom: 0.5rem;
}

.footer-link-text:hover {
    text-decoration: none;
    transition: .3ms ease;
    color: #FF6B6B;
    cursor: pointer;
}
.footer-contact-details{
    display: flex;
    flex-direction: column;
    margin-left: 0;
    padding-left: 0;
}
.footer-bottom {
    text-align: center;
    justify-content: center;
    font-size: 1rem;
    font-family: "Raleway", monospace;
    font-weight: 400;
}

@media (max-width: 430px) {
    .first-footer {
        text-align: center;
        padding: 0;
    }

    .footer {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 6rem;
        padding-right: 6rem;
        width: 100%;
        gap: 10px;
    }

    .footer-link-1, .footer-link-2, .footer-link-3 {
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }

}