.about-container {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.abt-text {
    opacity: 0; /* Start hidden */
    animation: fadeIn 1s ease-in forwards; /* Apply fade-in animation */
}

/* Define the keyframes for the fade-in effect */
@keyframes fadeIn {
    from {
        opacity: 0; /* Start completely transparent */
        transform: translateY(20px); /* Start slightly below */
    }
    to {
        opacity: 1; /* End fully visible */
        transform: translateY(0); /* Move to original position */
    }
}


.about-hero {
    position: relative;
    background-image: url("/public/partners/about-us-hero.webp");
    background-size: cover;
    background-position: center;
    height: 50vh;
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: center;
    align-items: center;
}

.about-hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(18, 13, 38);
    opacity: 0.9;
    z-index: 0;
}

.about-hero h4 {
    font-size: 4rem;
    text-transform: capitalize;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
}

.about-hero h4, .about-hero p {
    position: relative;
    z-index: 1;
    text-align: center;
    color: white;
}

.about-hero p {
    font-size: 1.5rem;
    font-family: "Raleway", monospace;
    font-weight: 300;
}

.about-content {
    margin-top: 3rem;
    margin-left: 3rem;
    margin-right: 2rem;
    overflow: hidden; /* Ensures the image stays within the container */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.about-content-left {
    width: 40%;
    height: 50%;
    overflow: hidden;
}

.about-content-left img {
    margin: 10px;
    display: block;
    width: 600px;
    object-fit: cover;
    filter: grayscale(50%);
    cursor: pointer;
    transition: all ease 2s;
}

.about-content-left img:hover {
    scale: 1.3;
}

.about-content-right {
    width: 50%;
}

/* .about-content-right h4 {
    font-size: 6rem;
    line-height: 1.1;
    font-weight: 700;
    text-align: center;
    font-family: "Open Sans", sans-serif;
} */

.mission-para {
    font-size: 1.5rem;
    padding-top: 40px;
    line-height: 2rem;
    text-align: justify;
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: normal;
}

@media (max-width: 430px) {
    .about-hero p {
        margin-top: 1rem;
    }

    .about-container {
        margin-top: 8rem;
        width: 100%;
    }

    .about-content {
        flex-direction: column;
        gap: 20px;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }

    .about-content-left {
        display: none;
    }

    .about-content-right {
        width: 100%;
    }
.mission-para {
        font-size: 1.5rem;
    }
}