.contuct-us {
  margin-top: 4rem;
}

.element {
  position: relative;
  background-image: url('https://indiaeducationdiary.in/wp-content/uploads/2022/08/University-of-Greenwich.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  width: 100%;
}

.element::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(2, 10, 47, 0.748);
  z-index: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0; /* Start transparent */
    transform: translateY(20px); /* Optional: add a little slide up effect */
  }
  100% {
    opacity: 1; /* Fully visible */
    transform: translateY(0); /* End at the original position */
  }
}

.element h1 {
  position: relative;
  z-index: 2;
  color: white;
  text-align: center;
  padding-top: 11rem;
  font-size: 4rem;
  font-family: "Merriweather", serif;
  font-weight: bolder;
  animation: fadeIn 1s ease forwards; /* Apply fadeIn animation */
}


.form-c {
  display: flex;
  gap: 5rem;
  padding: 80px;
  justify-content: center;
  align-items: center;
}

.form-container {
  background-color: #f6f6f6;
  padding: 30px;
  width: 50%;
  justify-content: center;
  align-items: center;
}

h2 {
  text-align: center;
  font-size: 2.5rem;
  font-family: "Merriweather", serif;
  /* color: #333; */
  margin-bottom: 2rem;
  margin-top: 10px;
  font-weight: bold;
  color: rgb(58, 58, 58);
}

.form-group {
  margin-bottom: 1rem;
  width: 100%;
}

.step-devision {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 1rem; */
}

.form-group {
  flex: 1;
  margin-right: 10px;
}

.form-group:last-child {
  margin-right: 0;
}

input[type="text"],
input[type="tel"],
input[type="email"],
select {
  width: 100%;
  padding: 12px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s;
  color: #555;
}

input[type="text"]:focus,
input[type="tel"]:focus,
input[type="email"]:focus,
select:focus {
  border-color: #007bff;
  outline: none;
}

.submit-button {
  width: 30%;
  padding: 10px;
  background-color: #0029CF;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  margin-top: 1.8rem;
  margin-bottom: 1rem;
 
}

.submit-button:hover {
  background-color: #3c5bd7;
}

.success-message {
  margin-top: 20px;
  color: green;
  text-align: center;
}

.success-message {
  text-align: center;
  color: #28a745;
  font-weight: bold;
  margin-top: 20px;
}

.map-container {
  width: 45%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.map-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.contact-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.social {
  display: flex;
  align-items: center;
  gap: 25px;
  padding-top: 1.2rem;
  padding-left: 1.5rem;
}

.social i {
  font-size: 30px;
  color:rgb(58, 58, 58) ;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social i:hover {
  color: #0029CF;
  transform: scale(1.2);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .form-c {
      flex-direction: column; /* Stack elements vertically */
      padding: 40px 20px; /* Reduce padding */
  }

  .form-container {
      width: 90%; /* Make form container wider on small screens */
      justify-content: center;
      align-items: center;
  }

  h2 {
      font-size: 2rem; /* Adjust heading size */
   
  }

  .element h1 {
      font-size: 3rem; /* Reduce heading size */
      padding-top: 8rem; /* Adjust padding */
  }

  .map-container {
      width: 100%; /* Full width for smaller screens */
      margin-top: 20px; /* Add margin for spacing */
  }

  .social i {
      font-size: 24px; /* Smaller icon size */
  }
  .step-devision{
    display: flex;
    flex-direction: column;
    /* margin-bottom: 1rem; */
  }
  
}

@media (max-width: 480px) {
  .element h1 {
      font-size: 2.5rem; /* Further reduce heading size */
  }

  h2 {
      font-size: 1.8rem; /* Adjust heading size for small screens */
  }
  .location{
    display: none;
  }
}


/* Custom styling for the SweetAlert popup */
.custom-swal-popup {
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Soft shadow */
}

/* Custom styling for the SweetAlert confirm button */
.custom-swal-button {
  background-color: #4CAF50 !important; /* Custom button color */
  color: white !important; /* Button text color */
  padding: 10px 20px !important; /* Add padding */
  font-size: 16px !important; /* Increase font size */
  border-radius: 5px !important; /* Button border radius */
}


