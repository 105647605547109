.main{
    margin-top: 5rem;
}

.main-page {
    position: relative;
    background-image: url('https://images4.alphacoders.com/705/thumb-1920-705943.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 9, 52, 0.708);
    z-index: 1;
}

.main-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    opacity: 0; /* Hidden by default */
    animation: fadeInUp 1.5s ease forwards; /* Trigger fade-in animation */
    font-weight: bolder;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px); /* Start slightly lower */
    }
    to {
        opacity: 1;
        transform: translateY(0); /* Reset to original position */
    }
}

.main-head {
    color: white;
    font-size: 4.5rem;
    font-weight: bolder;
    font-family: "Merriweather", serif;
    text-align: center;
}

.main-subhead {
    color: white;
    font-size: 1.5rem;
    padding-top: 1rem;
    font-family: "Merriweather", serif;
    font-weight: bolder;
}

/* Keyframes for the scroll animation */
@keyframes fadeInSlideUp {
    0% {
        opacity: 0;
        transform: translateY(20px); /* Start slightly lower */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* End at the original position */
    }
}

/* Heading styles */
.heading-text {
    font-family: "Merriweather", serif;
    font-size: 3rem;
    color: rgb(58, 58, 58);
    padding-left: 70px;
    opacity: 0; /* Start hidden */
    transform: translateY(20px); /* Start slightly lower */
    transition: opacity 0.8s ease, transform 0.8s ease; /* Transition for smooth effect */
}

.heading-text.visible {
    opacity: 1; /* Show the element */
    transform: translateY(0); /* Reset to original position */
}

.catchy-text {
    color: #0029CF;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .main-page {
        height: 100vh; /* Adjust height for tablet screens */
     
    }

    .main-head {
        font-size: 2rem; /* Adjust heading font size */
    }

    .main-subhead {
        font-size: 1rem; /* Adjust subheading font size */
    }

    .heading-text {
        font-size: 1.3rem; /* Adjust heading text size */
        padding-left: 20px; /* Adjust padding for smaller screens */
    }
}

@media (max-width: 430px) { /* For iPhone 14 Pro Max and similar devices */
    .main-page {
        height: 100vh; /* Adjust height for mobile screens */
    }

    .main-head {
        font-size: 2.5rem; /* Adjust heading font size */
        text-align: center;
    }

    .main-subhead {
        font-size: 1rem; /* Adjust subheading font size */
        text-align: center;
    }

    .heading-text {
        font-size: 1.3rem; /* Adjust heading text size */
        padding-left: 10px;
        text-align: center;
    }
}
