@keyframes fadeInSlideUp {
  0% {
    opacity: 0; /* Start transparent */
    transform: translateY(20px); /* Start slightly lower */
  }
  100% {
    opacity: 1; /* Fully visible */
    transform: translateY(0); /* End at the original position */
  }
}

/* Heading styles */
.testimonial-feedback {
    font-family: "Merriweather", serif;
    font-size: 3rem;
    color: #232323;
    opacity: 0; /* Start hidden */
    transform: translateY(20px); /* Start slightly lower */
    transition: opacity 0.8s ease, transform 0.8s ease; /* Transition for smooth effect */
}

.testimonial-feedback.visible {
    opacity: 1; /* Show the element */
    transform: translateY(0); /* Reset to original position */
}

.testimonial-feedback{
  margin-top: 2rem;
  margin-bottom: 2rem;
}
/* Container for the testimonials */
.testimonial-data {
  width: 85%;
  margin: 0 auto;
  padding: 20px 0;
}



/* Styling for each testimonial card */
.testimonial-card {
  background-color: #eaeaea;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px;
  border: 1px solid #b2b2b2;
  transition: transform 0.6s ease, box-shadow 0.6s ease;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.testimonial-card:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
}

/* Image section */
.testimonial-img img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid #686868;
  margin-top: -4rem;
}

/* Client name styling */
.name-client {
  font-weight: bold;
  font-size: 1.1rem;
  color: #302f2f;
  margin-top: 1rem;
font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

/* University name styling */
.name-university {
  font-size: 14px;
  color: #0029CF;
  display: block;
  margin-bottom: 10px;
  
}

/* Review text styling */
.reviews {
  font-size: 12px;
  color: #555;
  font-style: italic;
  margin-bottom: 15px;
  display: block;
}

/* Course name styling */
.name-course {
  margin-top: 20px;
  background-color: rgb(80, 77, 77);
  color: white;
  padding: 8px;
  border-radius: 10px;
  border: 1px solid color#21386D;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

/* Slick Slider adjustments */
.slick-slider .slick-slide {
  padding: 0 10px;
}

