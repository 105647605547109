@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #fcfcfc;
}

.nav {
    background-color: #ffffff;
   position: fixed;
    width: 100%;
    z-index: 1000;
    padding-top: 10px;
    padding-bottom: 10px;
  }

.container {
  max-width: 1100px;
  /* margin: 0 auto;
  padding: 0 15px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-brand {
    align-content: center;
    justify-content: center;
    /* padding-top: 1rem; */
    height: auto;
    width: 9rem;
}

.menu-icon {
  display: none;
}



.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  gap: 30px;
}

/* .nav-elements ul li:not(:last-child) {
  margin-right: 60px;
} */

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #0029CF;
  text-decoration: none;
  transition: transform 0.7s ease;
}
.nav-elements ul a:hover {
  color: #574c4c;
  transform: scale(1.2);
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
}

.burger {
    position: relative;
    display: block;
    cursor: pointer;
    user-select: none;
    order: -1;
    z-index: 10;
    width: 1.6rem;
    height: 1.15rem;
    border: none;
    outline: none;
    background: none;
    visibility: visible;
    transform: rotate(0deg);
    transition: 0.35s ease;
}

.nav {
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  z-index: 1000;
  padding: 10px 0;
  transition: background-color 0.5s ease, box-shadow 0.5s ease;
}



.nav.scrolled {
  background-color: #0029cf; /* Dark background on scroll */
  color: #ffffff; /* White text on scroll */
}

.nav .nav-brand {
  transition: filter 0.5s ease;
}

.nav .nav-brand.scrolled {
  filter: brightness(0) invert(1); /* Invert logo colors */
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #0029cf;
  text-decoration: none;
  transition: transform 0.7s ease, color 0.5s ease;
}

.nav.scrolled .nav-elements ul a {
  color: #ffffff; /* Change text color on scroll */
}

.burger-line {
  background-color: #1b1b1c;
  transition: background-color 0.5s ease;
}

.nav.scrolled .burger-line {
  background-color: #ffffff; /* Change burger color on scroll */
}



@media (max-width: 768px) {
  /* .nav-elements ul li:not(:last-child) {
    margin-right: none;
  } */
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #ffffff;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav.scrolled .nav-elements {
    background-color: #0029cf; /* Dark background on scroll */
  }
  
  .nav-elements ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nav-elements ul li {
    margin-right: unset;
    /* margin-top: 22px; */
  }
}

@media only screen and (min-width: 48rem) {
    .burger {
        display: none;
        visibility: hidden;
    }

}

.burger-line {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 2px;
    border: none;
    outline: none;
    opacity: 1;
    border-radius: 1rem;
    transform: rotate(0deg);
    background-color: #1b1b1c;
    transition: 0.25s ease-in-out;
}

.burger-line:nth-child(1) {
    top: 0px;
}

.burger-line:nth-child(2) {
    top: 0.5rem;
    width: 70%;
}

.burger-line:nth-child(3) {
    top: 1rem;
}

.burger.is-active .burger-line:nth-child(1) {
    top: 0.5rem;
    transform: rotate(135deg);
}

.burger.is-active .burger-line:nth-child(2) {
    opacity: 0;
    visibility: hidden;
}

.burger.is-active .burger-line:nth-child(3) {
    top: 0.5rem;
    transform: rotate(-135deg);
}